const message2morse = {
  'ア': '－－・－－',
  'イ': '・－',
  'ウ': '・・－',
  'エ': '－・－－－',
  'オ': '・－・・・',
  'カ': '・－・・',
  'キ': '－・－・・',
  'ク': '・・・－',
  'ケ': '－・－－',
  'コ': '－－－－',
  'サ': '－・－・－',
  'シ': '－－・－・',
  'ス': '－－－・－',
  'セ': '・－－－・',
  'ソ': '－－－・',
  'タ': '－・',
  'チ': '・・－・',
  'ツ': '・－－・',
  'テ': '・－・－－',
  'ト': '・・－・・',
  'ナ': '・－・',
  'ニ': '－・－・',
  'ヌ': '・・・・',
  'ネ': '－－・－',
  'ノ': '・・－－',
  'ハ': '－・・・',
  'ヒ': '－－・・－',
  'フ': '－－・・',
  'ヘ': '・',
  'ホ': '－・・',
  'マ': '－・・－',
  'ミ': '・・－・－',
  'ム': '－',
  'メ': '－・・・－',
  'モ': '－・・－・',
  'ヤ': '・－－',
  'ユ': '－・・－－',
  'ヨ': '－－',
  'ラ': '・・・',
  'リ': '－－・',
  'ル': '－・－－・',
  'レ': '－－－',
  'ロ': '・－・－',
  'ワ': '－・－',
  'ヰ': '・－・・－',
  'ヱ': '・－－・・',
  'ヲ': '・－－－',
  'ン': '・－・－・',
  'ー': '・－－・－',
  '゛': '・・',
  '゜': '・・－－・',
  '、': '・－・－・－',
  '。': '・－・－・・',
  '？': '・・－－・・',
  '！': '－・－・－－',
  '・': '･', // HACK: 文中の「・」が「ヘ」に変換されるのを避けるために「･」に置き換えておく
  '’': '・－－－－・',
  '”': '・－・・－・',
  '＆': '・－・・・',
  '：': '－－－・・・',
  '；': '－・－・－・',
  '＝': '－・・・－',
  '−': '－・・・・－',
  '＿': '・・－－・－',
  '＄': '・・・－・・－',
  '＠': '・－－・－・',
  '　': '／',
  '0': '_____',
  '1': '.____',
  '2': '..___',
  '3': '...__',
  '4': '...._',
  '5': '.....',
  '6': '_....',
  '7': '__...',
  '8': '___..',
  '9': '____.',
  'A': '._',
  'B': '_...',
  'C': '_._.',
  'D': '_..',
  'E': '.',
  'F': '.._.',
  'G': '__.',
  'H': '....',
  'I': '..',
  'J': '.___',
  'K': '_._',
  'L': '._..',
  'M': '__',
  'N': '_.',
  'O': '___',
  'P': '.__.',
  'Q': '__._',
  'R': '._.',
  'S': '...',
  'T': '_',
  'U': '.._',
  'V': '..._',
  'W': '.__',
  'X': '_.._',
  'Y': '_.__',
  'Z': '__..',
  '.': '._._._',
  ',': '__..__',
  '?': '..__..',
  "'": '.____.',
  '/': '_.._.',
  '(': '_.__.',
  ')': '_.__._',
  '&': '._...',
  ':': '___...',
  ';': '_._._.',
  '=': '_..._',
  '+': '._._.',
  '-': '_...._',
  '_': '..__._',
  '"': '._.._.',
  '$': '..._.._',
  '@': '.__._.',
  ' ': '/',
};

const fullMessage2morse = {
  ...message2morse,
  'ァ': '－－・－－',
  'ィ': '・－',
  'ゥ': '・・－',
  'ェ': '－・－－－',
  'ォ': '・－・・・',
  'ッ': '・－－・',
  'ャ': '・－－',
  'ュ': '－・・－－',
  'ョ': '－－',
  'ヮ': '－・－',
  'ヵ': '・－・・',
  'ヶ': '－・－－',
  'ガ': '・－・・ ・・',
  'ギ': '－・－・・ ・・',
  'グ': '・・・－ ・・',
  'ゲ': '－・－－ ・・',
  'ゴ': '－－－－ ・・',
  'ザ': '－・－・－ ・・',
  'ジ': '－－・－・ ・・',
  'ズ': '－－－・－ ・・',
  'ゼ': '・－－－・ ・・',
  'ゾ': '－－－・ ・・',
  'ダ': '－・ ・・',
  'ヂ': '・・－・ ・・',
  'ヅ': '・－－・ ・・',
  'デ': '・－・－－ ・・',
  'ド': '・・－・・ ・・',
  'バ': '－・・・ ・・',
  'ビ': '－－・・－ ・・',
  'ブ': '－－・・ ・・',
  'ベ': '・ ・・',
  'ボ': '－・・ ・・',
  'パ': '－・・・ ・・－－・',
  'ピ': '－－・・－ ・・－－・',
  'プ': '－－・・ ・・－－・',
  'ペ': '・ ・・－－・',
  'ポ': '－・・ ・・－－・',
  'ヴ': '・・－ ・・',
  'ヷ': '－・－ ・・',
  'ヸ': '・－・・－ ・・',
  'ヹ': '・－－・・ ・・',
  'ヺ': '・－－－ ・・',
};

const morse2message = Object.keys(message2morse).reduce((obj, message) => {
  obj[message2morse[message]] = message;
  return obj;
}, {});

export const decodeMorse = (node) => {
  if (node.nodeType === node.TEXT_NODE) {
    const codes = node.nodeValue.split(' ');
    let replaced = 0;
    const kana = codes.map((code) => {
      if (morse2message[code]) {
        replaced++;
        return morse2message[code];
      }
      return code;
    }).join('');
    if (replaced > 2) {
      node.nodeValue = `≪${kana}≫`;
    }
  } else {
    for (let i = 0; i < node.childNodes.length; i++) {
      decodeMorse(node.childNodes[i]);
    }
  }
};

export const encodeMorse = (text) => {
  const normalized = text.replace(/[ぁ-ん]/g, function(s) {
    return String.fromCharCode(s.charCodeAt(0) + 0x60);
  }).replace(/[Ａ-Ｚａ-ｚ０-９]/g, function(s) {
    return String.fromCharCode(s.charCodeAt(0) - 0xFEE0);
  }).replaceAll('（', '(')
    .replaceAll('）', ')')
    .toUpperCase();

  let shouldPrependSpace = false;
  return [...normalized].map((char) => {
    console.log(char);
    let code;
    if (fullMessage2morse[char]) {
      if (shouldPrependSpace) {
        code = ` ${fullMessage2morse[char]} `;
      } else {
        code = `${fullMessage2morse[char]} `;
      }
      shouldPrependSpace = false;
      return code;
    }
    shouldPrependSpace = true;
    return char;
  }).join('');
};
